import { type } from 'src/core/shared/utility/utilityHelpers';
import { Action } from '@ngrx/store';

export const ActionTypes = {
    GET_PARTNER: type('[partner] get partner'),
    GET_PARTNER_SUCCESS: type('[partner] get partner success'),
    GET_PARTNER_FAIL: type('[partner] get partner fail'),

};
export class GetPartner implements Action {
    type = ActionTypes.GET_PARTNER;

    constructor(public payload: any) {
    }
}

export class GetPartnerSuccess implements Action {
    type = ActionTypes.GET_PARTNER_SUCCESS;

    constructor(public payload: any) {
    }
}

export class GetPartnerFail implements Action {
    type = ActionTypes.GET_PARTNER_FAIL;

    constructor(public payload: any) {
    }
}
export type Actions
 = GetPartner |
      GetPartnerSuccess |
      GetPartnerFail ;
