import { Api } from '../providers/api/api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable()
export class PartnerService extends Api {
    private base: string;
    public doGetPartner(params): Observable<any> {
        this.base = this.getBaseUrl();
        const varia = this.http.get(this.base + 'partner/choose-partner', {
            params: params
          });
          varia.subscribe((data: any) => {
          });

        return varia;
    }

}
