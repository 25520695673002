import { Injectable } from '@angular/core';


@Injectable()
export class UserPartner {
    static doGetPartner(objeto: { partnerCode: number; }) {
        throw new Error('Method not implemented.');
    }
    partner: any;
constructor() {
this.partner = null;
}
    public getPartner() {
        return this.partner;
    }
    public setPartner(newPartner) {
this.partner = newPartner;
    }

}
