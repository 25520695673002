import { Component, OnInit, Input } from '@angular/core';
import { ListsSandbox } from '../../../../../core/lists/lists.sandbox';

@Component({
  selector: 'app-categories-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {
  categories: any;
  constructor(public listSandbox: ListsSandbox) { }

  ngOnInit() {
    this.listSandbox.categoryList$.subscribe(data => {
      this.categories = data;
    });
  }

}
