export class PartnerModel {
    public theme: string;
    public theme_aux: string;
    public uuid: number;
    public partner_code: string;
    public partner_name: string;
    public theme_hexa: string;
    public partner_id: number;
    public ad_org_id: number;
    public number_product_destination: string;
    public target_product_type_code: string;
    public destination_identification: string;           
    public type_identification_destiny: number;
    public url_base_rest: string;  

    constructor(partnerRequest: any) {
      this.partner_id = partnerRequest.partnerId || 0;
      this.theme = partnerRequest.theme || '';
      this.theme_aux = partnerRequest.theme_aux || '';
      this.uuid = partnerRequest.uuid || '';
      this.partner_code = partnerRequest.partnerCode || '';
      this.partner_name = partnerRequest.partnerName || '';
      this.theme_hexa = partnerRequest.theme_hexa || '';
      this.ad_org_id = partnerRequest.adOrgId || 0;
      this.number_product_destination = partnerRequest.numberProductDestination || '';
      this.target_product_type_code = partnerRequest.targetProductType_code || '';
      this.destination_identification = partnerRequest.destinationIdentification || '';
      this.type_identification_destiny = partnerRequest.typeIdentificationDestiny || 0;
      this.url_base_rest = partnerRequest.url_base_rest || '';
    }
  }
