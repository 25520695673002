import { createSelector } from 'reselect';
import * as fromPartner from './partner.reducer';
import { PartnerState } from './partner.state';
import { AppState } from '../../state.interface';

export const getState = (State:  AppState) => State.partner;
export const partnerLoading = createSelector(
    getState,
    fromPartner.GetPartnerLoading
  );
  export const partnerLoaded = createSelector(
    getState,
    fromPartner.GetPartnerLoaded
  );
  export const partnerFailed = createSelector(
    getState,
    fromPartner.GetPartnerFailed
  );
  export const getpartner = createSelector(
    getState,
    fromPartner.getPartner
  );

