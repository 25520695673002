import { Record } from 'immutable';
import { PartnerModel } from '../models/partner.model';


export interface PartnerState extends Map<string, any> {

    partner: any;
    getpartnerLoading: boolean;
    getpartnerLoaded: boolean;
    getpartnerFailed: boolean;
}
export const PartnerRecord = Record({

    getpartnerLoading: false,
    getpartnerLoaded: false,
    getpartnerFailed: false,
    partner: {},
  });
