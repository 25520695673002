import { PartnerState, PartnerRecord } from './partner.state';
import * as actions from '../action/partner.action';
import { PartnerModel } from '../models/partner.model';

export const initialState: PartnerState = (new PartnerRecord() as unknown) as PartnerState;
export function reducer(
  state = initialState,
  { type, payload }: any
): PartnerState {
  if (!type) {
    return state;
  }
  switch (type) {
    case actions.ActionTypes.GET_PARTNER: {
      return Object.assign({}, state, {
        GetPartnerLoading: true,
        GetPartnerLoaded: false,
        GetPartnerFailed: false
      });
    }

    case actions.ActionTypes.GET_PARTNER_SUCCESS: {
      const partnerTemp = new PartnerModel(payload.data);
      return Object.assign({}, state, {
        partner: partnerTemp,
        GetPartnerLoading: false,
        GetPartnerLoaded: true,
        GetPartnerFailed: false
      });
    }
    case actions.ActionTypes.GET_PARTNER_FAIL: {
      return Object.assign({}, state, {
        GetPartnerLoading: false,
        GetPartnerLoaded: true,
        GetPartnerFailed: true
      });
    }
    default: {
      return state;
    }
  }
}
export const GetPartnerLoading = (state: PartnerState) => state.getpartnerLoading;
export const GetPartnerLoaded = (state: PartnerState) => state.getpartnerLoaded;
export const GetPartnerFailed = (state: PartnerState) => state.getpartnerFailed;
export const getPartner = (state: PartnerState) => state.partner;
