import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { PartnerService } from '../partner.service';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { catchError } from 'rxjs/internal/operators';
import * as actions from './../action/partner.action';

@Injectable()
export class PartnerEffect {
  constructor(
    private actions$: Actions,
    private partner: PartnerService
  ) {}

  @Effect()
  doGetPartner$: Observable<Action> = this.actions$.pipe(
    ofType(actions.ActionTypes.GET_PARTNER),
    map((action: actions.GetPartner) => action.payload),
    switchMap(state => {
      return this.partner.doGetPartner(state).pipe(
        map(partner => new actions.GetPartnerSuccess(partner)),
        catchError(error => of(new actions.GetPartnerFail(error)))
      );
    })
  );
}
